import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
// import { GetAllPendingToken } from "../../../lib/api";
// import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";


const PendingToken = ({ data,pendingValue }) => {
  
  // console.log(data?.data?.tokensOnPeding?.length);
  
 
  const evenTokens = data?.data?.tokensOnPeding.filter(
    (item, index) => index % 2 === 0
  );
  const oddTokens = data?.data?.tokensOnPeding.filter(
    (item, index) => index % 2 === 1
  );
  return (
    <Paper className="flex" sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 440 }} className="flex">
        <Table>
          <TableBody>
            {evenTokens?.map((evenTokens, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableCell
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      fontWeight: "bold",
                      verticalAlign: "top",
                      borderRightWidth: "1px",
                    }}
                    onClick={() => pendingValue({val: evenTokens.TokenValue, prefix: evenTokens.DepPrefix})}
                  >
                    {`${evenTokens?.DepPrefix || Cookies.get("DepPrefix")}${evenTokens.TokenValue}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            {oddTokens?.map((oddTokens, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableCell
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      fontWeight: "bold",
                      verticalAlign: "top",
                      borderLeftWidth: "1px",
                    }}
                    onClick={() => pendingValue({val: oddTokens.TokenValue, prefix: oddTokens.DepPrefix})}
                  >
                    {/* {oddTokens.TokenValue} */}
                    {`${oddTokens?.DepPrefix || Cookies.get("DepPrefix")}${oddTokens.TokenValue}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PendingToken;
