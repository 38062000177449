import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  CreateCounterAPI,
  UpdateCounterAPI,
  GetAllDepartment,
} from "../../../lib/api";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import Cookies from "js-cookie";
import { useState } from "react";
import React, { useEffect } from "react";

const CreateCounter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCounter = location?.state?.CounterData;
  const counterId = isCounter?.CounterID;
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ["GetAllDepartment"],
    queryFn: () => GetAllDepartment(),
  });
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      counterNumber: isCounter?.CounterName || "",
      depId: isCounter?.DepID || "",
      // status: isCounter?.Status === true ? true : false,
      status: isCounter?.Status ?? true,
    },
  });
  const mutate = useMutation({
    mutationFn: (data) => UpdateCounterAPI(data, counterId),
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      toast.success("Update Counter Successful", {
        position: "top-right",
        closeOnClick: true,
      });
      navigate(RouteNavigation.counters);
    },
    onError: () => {
      toast.error("Update Counter Failed", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleCounterSubmit = async (data) => {
    setIsLoading(true);
    try {
      const dataResponse = await CreateCounterAPI(data);

      if (dataResponse.status === 201) {
        toast.success("Create Counter Successful", {
          position: "top-right",
          closeOnClick: true,
        });
        navigate(RouteNavigation.counters);
      } else {
        toast.error("Create Counter Failed " + (dataResponse.error || ""), {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        // if (error.response && error.response.status === 409) {

        toast.error("Department already exists. Please use a different name.", {
          position: "top-right",
          closeOnClick: true,
        });
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const CompanyId = Cookies.get("CompanyId");
    if (isCounter) {
      const updatedData = {
        ...data,
        companyId: CompanyId,
        depId: data.depId,
        counterName: data.counterNumber,
        status: data.status,
      };
      mutate.mutate(updatedData);
    } else {
      const updatedData = {
        ...data,
        companyId: CompanyId,
        depId: data.depId,
        counterName: data.counterNumber,
        status: data.status,
      };
      handleCounterSubmit(updatedData);
    }
  };

  useEffect(() => {
    setFocus("counterNumber");
  }, [setFocus]);

  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">
        {isCounter ? "Update" : "Create"} Counter
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full mt-5"
      >
        <div className="flex gap-10">
          <div>
            <label className="font-semibold">Counter Name</label>
            <input
              className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
              {...register("counterNumber", {
                required: true,
              })}
              
            />
            {errors.counterNumber && (
              <span className="text-red-600">This field is required</span>
            )}
          </div>
          <div>
            <label>Department Name</label>
            <select
              {...register("depId")}
              className="border-[1px] p-2 w-full h-12 mt-2 rounded-lg focus:outline-none focus:ring-2 bg-gray-200"
            >
              {data?.data?.department?.map((dept, index) => {
                return (
                  <option key={index + 1} value={dept.DepID}>
                    {dept.DepName}
                  </option>
                );
              })}
            </select>
            <p className="text-red-500 ml-5">{errors.depId?.message}</p>
          </div>
        </div>
        <div className="flex gap-4 mt-2">
          <input {...register("status")} type="checkbox" />
          <span>Active</span>
        </div>
        <div className="flex gap-5 justify-end">
          <Link to={RouteNavigation.counters}>
            <button className="flex items-center justify-center h-8 px-6 bg-gray-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-gray-700">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="flex items-center justify-center h-8 px-6 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          >
            {isLoading ? "Loading..." : `${isCounter ? "Update" : "Create"}`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCounter;
