import React, { useState } from "react";
import { FaBackspace } from "react-icons/fa";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import {
  GetToken,
  GetTokenCall,
  GetHoldToken,
  CompleteTokenStatus,
  TokenTransfer,
  GetAllPendingToken,
  GetAllDepartment,
  GetRecallToken,
  CheckTokenValue,
} from "../../../lib/api";

import { TokenReset } from "./TokenReset";
import PendingToken from "./PendingToken";
import TokenCall from "./TokenCallPopUp";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const Counter = () => {
  const location = useLocation();
  const { id } = useParams();
  const [depPrefix, setDepPrefix] = useState("");
  const [tokenValue, setTokenValue] = useState("");

  const [isLoadingCall, setIsLoadingCall] = useState(false);
  const [holdMenu, setHoldMenu] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPrefixPopUp, setOpenPrefixPopUp] = React.useState(false);
  const isCounterData = location?.state?.userCounterData;
  const CounterID = isCounterData?.CounterID;

  const { data: checktoken } = useQuery({
    queryKey: ["CheckTokenValue"],
    queryFn: () => CheckTokenValue(tokenValue || "0"),
    refetchInterval: 1000,
  });
  // console.log(checktoken?.data?.PendingToken);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["GetToken"],
    queryFn: () => GetToken(CounterID),
    refetchInterval: 1000,
  });

  const counterOnCallToken = data?.data?.latestCounterCallToken?.find(
    (item) => item.CounterID === parseInt(id)
  );

  const handleButtonClick = (digit) => {
    const newTokenValue =
      tokenValue === "0" ? digit.toString() : tokenValue + digit.toString();
    setTokenValue(newTokenValue);
    setDepPrefix("DEP");
  };

  const { data: holdToken } = useQuery({
    queryKey: ["GetHoldToken"],
    queryFn: () => GetHoldToken(),
    refetchInterval: 1000,
  });
  const evenTokens = holdToken?.data?.tokensOnHold.filter(
    (item, index) => index % 2 === 0
  );

  const oddTokens = holdToken?.data?.tokensOnHold.filter(
    (item, index) => index % 2 === 1
  );

  const CounterButton = ({ num }) => {
    return (
      <button
        className="flex items-center justify-center p-4 font-bold shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-50 h-50 bg-gray-100 text-center leading-50 text-base text-gray-700 m-1"
        onClick={() => handleButtonClick(num)}
      >
        {num}
      </button>
    );
  };

  const handleTokenComplete = async () => {
    if (isLoadingCall) return;
    setIsLoadingCall(true);
    try {
      const getCurrentToken = await CompleteTokenStatus(tokenValue);
      if (getCurrentToken.status === 200) {
        toast.success("Token Completed", {
          position: "top-right",
          closeOnClick: true,
        });
        setTokenValue("0");
      } else {
        toast.error("Token Completion failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || "Token Completion Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoadingCall(false);
      setOpen(false);
      Cookies.set("isHoldToken", false);
      refetch();
    }
    refetch();
    Cookies.set("isHoldToken", false);
  };

  const handleTokenCall = async (status) => {
    if (isLoadingCall) return;
    setIsLoadingCall(true);
    try {
      const getCurrentToken = await GetTokenCall(
        CounterID,
        status,
        tokenValue,
        depPrefix
      );
      if (getCurrentToken.status === 200) {
        toast.success("Token Called", {
          position: "top-right",
          closeOnClick: true,
        });
        setTokenValue("");
      } else {
        toast.error("Token call failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || "Token call failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoadingCall(false);
      setOpen(false);
      refetch();
    }
  };

  const handleHoldTokenCalling = (value) => {
    setTokenValue(value.TokenValue);
    Cookies.set("isHoldToken", true);
    handleClickOpen();
    refetch();
  };
  const handleClickOpen = () => {
    setOpen(true);
    refetch();
  };

  /// Token transfer
  const [openTransferToken, setOpenTransferToken] = useState(false);
  const handleTransferClick = () => {
    setOpen(false);
    setOpenTransferToken(true);
  };

  const [checkPrefix, setCheckPrefix] = useState(false);

  const handleCheckTokenValue = () => {
    setOpenPrefixPopUp(false);
    setCheckPrefix(true);
  };

  const handleCheckPrefix = async (DepPrefix) => {};

  const { data: allDepartment } = useQuery({
    queryKey: ["GetAllDepartment"],
    queryFn: () => GetAllDepartment(),
  });

  // token transfer
  const handleTransfer = async (depID, DepPrefix) => {
    const tokenToTransfer =
      tokenValue > 0 ? tokenValue : counterOnCallToken?.LastOnCallToken;

    if (!tokenToTransfer) {
      toast.error("No token value available for transfer.", {
        position: "top-right",
      });
      return;
    }

    try {
      const response = await TokenTransfer(tokenToTransfer, depID, DepPrefix);
      if (response.status === 200) {
        toast.success("Token Transfer Completed", {
          position: "top-right",
          closeOnClick: true,
          autoClose: 5000,
        });
        setOpenTransferToken(false);
      } else {
        toast.error("Token Transfer Failed", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Token Transfer Failed", {
        position: "top-right",
      });
    }
  };

  const { data: pendingvalue } = useQuery({
    queryKey: ["GetAllPendingToken"],
    queryFn: () => GetAllPendingToken(),
    refetchInterval: 1000,
  });
   console.log(pendingvalue?.data?.tokensOnPeding);
   

  // Token recall
  const pendingCount = pendingvalue?.data?.tokensOnPeding?.length;

  const handleRecallToken = async () => {
    if (isLoadingCall) return;
    setIsLoadingCall(true);
    try {
      const recallTokenResponse = await GetRecallToken(
        counterOnCallToken.TokenID
      );
      if (recallTokenResponse.status === 200) {
        toast.success("Token Recalled", {
          position: "top-right",
          closeOnClick: true,
        });
        // setTokenValue(counterOnCallToken?.LastOnCallToken || "0");
      } else {
        toast.error("Token Recall failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Token Recall failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoadingCall(false);
      refetch();
    }
  };

  const holdTokenCount = holdToken?.data?.tokensOnHold?.length;

  return (
    <div className="max-w-[1024px] m-auto select-none px-4 py-5">
      <div className="w-fit text-center">
        <h4 className="text-3xl font-bold mb-2">
          {isCounterData?.CounterName || "Counter"}{" "}
          {counterOnCallToken?.LastOnCallToken ? (
            <span>({counterOnCallToken?.LastOnCallToken})</span>
          ) : (
            ""
          )}
        </h4>
      </div>
      <div className="flex justify-between items-center">
        <div className="mb-2">
          {Cookies.get("resetToken") === "true" ? <TokenReset /> : null}
          <div style={{ width: "100px" }}></div>
        </div>
        {/* Transfer button */}
        {/* { Number(tokenValue) === counterOnCallToken?.LastOnCallToken ? */}
        <div
          className={`w-fit flex justify-center gap-1   sm:gap-2 font-medium rounded-lg text-xs sm:text-sm px-3 sm:px-5 py-2 sm:py-2.5 text-center mb-2 text-white  ${
            counterOnCallToken?.LastOnCallToken ||
            (tokenValue && tokenValue > 0)
              ? "bg-[#2552EB]"
              : "bg-gray-400 cursor-not-allowed "
          } ${"mr-[50%]"}
              `}
          onClick={
            counterOnCallToken?.LastOnCallToken ||
            (tokenValue && tokenValue > 0)
              ? handleTransferClick
              : null
          }
          
        >
          <img
            className="w-6 "
            src="/image/arrow-goes-left-right-icon.svg"
            alt="element"
          />
          Token Transfer
        </div>
      </div>
      <Button onClick={handleCheckTokenValue}>check</Button>
      <Dialog open={checkPrefix} onClose={() => setCheckPrefix(false)}>
        <DialogContent>
          <DialogContentText className="select-none flex justify-between items-center">
            Select Department Prefix
          </DialogContentText>
          {checktoken?.data?.PendingToken &&
          checktoken.data.PendingToken.length > 0 ? (
            <div className="mt-4 flex gap-2  ">
              {checktoken?.data?.PendingToken?.map((depPrefix) => (
                <button
                  key={depPrefix}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transform transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400"
                  // onClick={() => handleDepPrefixSelection(depPrefix)}
                  onClick={() => handleCheckPrefix(depPrefix)}
                >
                  {depPrefix.DepPrefix}
                </button>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-600">
              No department prefixes available.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCheckPrefix(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* token transfer modal */}

      <Dialog
        open={openTransferToken}
        onClose={() => setOpenTransferToken(false)}
        aria-labelledby="transfer-dialog-title"
        aria-describedby="transfer-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="transfer-dialog-description"
            className="select-none flex justify-between items-center"
          >
            Select a department to transfer the token:
          </DialogContentText>

          <div className="mt-4 flex flex-wrap gap-2 w- max-h-[70vh] overflow-y-auto">
            {allDepartment?.data?.department?.map((dept) => {
              // console.log("dept :", dept?.DepName);
              // allDepartment?.data?.department?.department
              const DepID = Cookies.get("DepID");
              const isCurrentDept = Number(DepID) === dept.DepID;
              return isCurrentDept ? null : (
                <button
                  key={dept.DepID}
                  onClick={() => {
                    handleTransfer(dept.DepID);
                    setOpenTransferToken(false);
                  }}
                  className="mt-4 ml-2 h-11  px-4 py-2 bg-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                  {dept.DepName}
                </button>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTransferToken(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <div className="flex flex-col justify-between">
            <div className="w-full">
              <h4 className="text-lg">Token No:</h4>
              <span className="text-4xl font-bold">
                {isLoading
                  ? "Loading..."
                  : `${data?.data?.CallToken} of ${data?.data?.TotalToken}`}
              </span>
            </div>
            <input
              className={`text-6xl font-bold h-16 w-full input-no-cursor bg-transparent focus:outline-none focus:outline-0 text-right`}
              value={tokenValue}
              readOnly
            />
          </div>
          <div className="grid grid-cols-3 gap-2 mt-2">
            <CounterButton num={"1"} />
            <CounterButton num={"2"} />
            <CounterButton num={"3"} />
            <CounterButton num={"4"} />
            <CounterButton num={"5"} />
            <CounterButton num={"6"} />
            <CounterButton num={"7"} />
            <CounterButton num={"8"} />
            <CounterButton num={"9"} />
            <button
              onClick={() => setTokenValue(tokenValue.toString().slice(0, -1))}
              className="flex items-center justify-center p-4 font-bold shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-50 h-50 bg-gray-100 rounded-md text-center leading-50 text-base text-gray-700 m-1"
            >
              <FaBackspace />
            </button>

            <CounterButton num={"0"} />

            {Number(tokenValue) === counterOnCallToken?.LastOnCallToken ||
            (counterOnCallToken?.LastOnCallToken && !tokenValue) ? (
              <button
                className="flex items-center justify-center font-bold p-4 rounded-md leading-50 text-base text-center m-1 text-white w-50 h-50 bg-[#2552EB]"
                onClick={handleRecallToken}
              >
                Recall
              </button>
            ) : (
              <button
                onClick={() => {
                  if (!isLoadingCall) {
                    handleClickOpen();
                  }
                }}
                className="flex items-center justify-center p-4 font-bold shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-50 h-50 bg-gray-100 rounded-md text-center leading-50 text-base text-gray-700 m-1"
              >
                CALL
              </button>
            )}
          </div>
          <div className="grid grid-cols-2 gap-2 mt-4">
            <div
              onClick={() => {
                if (!isLoadingCall) {
                  handleTokenCall(1);
                }
              }}
              className={`bg-gradient-to-b from-[#2552EB] to-[#3C73F6] hover:from-[#3C73F6] hover:to-[#2552EB] transform-colors duration-300 flex flex-col items-center justify-center p-5 cursor-pointer w-full text-white text-lg gap-2`}
              disabled={isLoadingCall}
            >
              <img className="w-9" src="/image/element_1.svg" alt="element" />
              {isLoadingCall ? "Calling" : "Hold & Next"}
            </div>
            <div
              onClick={() => {
                if (!isLoadingCall) {
                  handleTokenCall(0);
                }
              }}
              className="flex flex-col items-center justify-center p-5 cursor-pointer bg-gradient-to-b from-[#358000] to-[#80C259] w-full text-white text-lg gap-2"
              disabled={isLoadingCall}
            >
              <img className="w-9 " src="/image/element_2.svg" alt="element" />
              {isLoadingCall ? "Calling" : "Next Token"}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div
              onClick={() => setHoldMenu(true)}
              className={`${
                holdMenu ? "bg-[#2552EB]" : "bg-gray-400"
              } cursor-pointer transform-all duration-300 flex justify-center gap-2 text-center w-full text white p-4 rounded-tl-3xl text-white text-2xl uppercase font-bold`}
            >
              <Badge
                badgeContent={holdTokenCount}
                color="secondary"
                className="m-2"
              >
                <img
                  className="w-11"
                  src="/image/element_1.svg"
                  alt="element"
                />{" "}
              </Badge>
              Token on hold
            </div>
            <div
              onClick={() => setHoldMenu(false)}
              className={`${
                holdMenu ? "bg-gray-400" : "bg-[#2552EB]"
              } cursor-pointer transform-all duration-300 flex justify-center gap-2 text-center w-full text white p-4 rounded-tr-3xl text-white text-2xl uppercase font-bold`}
            >
              <Badge
                badgeContent={pendingCount}
                color="secondary"
                className="m-2"
              >
                <img
                  className="w-11"
                  src="/image/element_1.svg"
                  alt="element"
                />
              </Badge>
              Pending Token
            </div>
          </div>
          {holdMenu ? (
            <Paper className="flex" sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }} className="flex">
                <Table>
                  <TableBody>
                    {evenTokens?.map((evenTokens, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "25px",
                              fontWeight: "bold",
                              verticalAlign: "top",
                              borderRightWidth: "1px",
                            }}
                            onClick={() => handleHoldTokenCalling(evenTokens)}
                          >
                            {/* {evenTokens.TokenValue} */}
                            {`${
                              evenTokens?.DepPrefix || Cookies.get("DepPrefix")
                            }${evenTokens.TokenValue}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody>
                    {oddTokens?.map((oddTokens, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "25px",
                              fontWeight: "bold",
                              verticalAlign: "top",
                              borderLeftWidth: "1px",
                            }}
                            onClick={() => handleHoldTokenCalling(oddTokens)}
                          >
                            {/* {oddTokens.TokenValue} */}
                            {`${
                              oddTokens?.DepPrefix || Cookies.get("DepPrefix")
                            }${oddTokens.TokenValue}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <PendingToken
              pendingValue={(e) => {
                setTokenValue(e.val);
                setDepPrefix(e.prefix);
              }}
              data={pendingvalue}
            />
          )}
        </div>
      </div>

      <TokenCall
        open={open}
        opens={openPrefixPopUp}
        setOpen={setOpen}
        handleTokenCall={handleTokenCall}
        isLoadingCall={isLoadingCall}
        handleTokenComplete={handleTokenComplete}
        tokenValue={counterOnCallToken?.LastOnCallToken}
      />
    </div>
  );
};
export default Counter;
