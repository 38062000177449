export const constant = {
  // base_url: "https://api-xeniatoken.onrender.com/api/",
  // base_url: "http://localhost:3000/api/",

  base_url: "https://xeniatokenapi.onrender.com/api",
  

  image_upload_url: "https://apiimage.xeniapos.com/TMS/image/upload",
  image_render_url:
    "https://apiimage.xeniapos.com/TMS/assest/uploads?fileName=",
};
